// import React from 'react';
// import { Input, Button, Avatar } from 'antd';
// import { SendOutlined, UserOutlined } from '@ant-design/icons';
// import { useNavigate } from 'react-router-dom'; // Import React Router's navigation hook

// const LandingPage: React.FC = () => {
//   const navigate = useNavigate(); // React Router hook for navigation

//   const goToHomePage = () => {
//     navigate('/home'); // Navigate to the home page
//   };

//   const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
//     if (event.key === 'Enter') {
//       goToHomePage(); // Trigger navigation when Enter is pressed
//     }
//   };

//   return (
//     <div className="landing-page">
//       {/* Overlay for fading effect */}
//       <div className="fade-overlay"></div>

//       {/* Main Content */}
//       <div className="landing-page-content">
//         <h1 className="landing-page-title">
//           Welcome to <span className="coral-ai-highlight">CoralAI</span> ✨
//         </h1>
//         <p className="landing-page-subtitle">
//           An NLP-based tool to answer Coral-related questions.
//         </p>

//         {/* Search Input Section */}
//         <div className="search-section">
//           <Input
//             placeholder="Ask CoralAI a question..."
//             size="large"
//             className="search-input"
//             onKeyPress={handleKeyPress} // Handle Enter key press
//             suffix={
//               <Button
//                 type="primary"
//                 shape="circle"
//                 icon={<SendOutlined />}
//                 onClick={goToHomePage} // Attach the navigation action
//               />
//             }
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LandingPage;



// import React, { useState } from 'react';
// import { Input, Button, Avatar } from 'antd';
// import { SendOutlined, UserOutlined } from '@ant-design/icons';

// const LandingPage: React.FC = () => {
//   const [emailPromptVisible, setEmailPromptVisible] = useState(false); // State to show/hide email input
//   const [email, setEmail] = useState(''); // State to store email input
//   const [feedbackMessage, setFeedbackMessage] = useState(''); // State for feedback after submission

//   const handleGoToEmailPrompt = () => {
//     setEmailPromptVisible(true); // Show email input
//   };

//   const handleEmailSubmit = () => {
//     if (email.trim()) {
//       setFeedbackMessage(`Thank you! We'll follow up soon!`);
//       setEmail(''); // Clear the input field
//       setEmailPromptVisible(false); // Hide email input
//     } else {
//       setFeedbackMessage('Please enter a valid email.');
//     }
//   };

//   return (
//     <div className="landing-page">
//       {/* Overlay for fading effect */}
//       <div className="fade-overlay"></div>

//       {/* Main Content */}
//       <div className="landing-page-content">
//         <h1 className="landing-page-title">
//           Welcome to <span className="coral-ai-highlight">Coral X AI</span> ✨
//         </h1>
//         <p className="landing-page-subtitle">
//           An LLM tool trained on thousands of coral-related publications to assist practitioners, newcomers, researchers, and policymakers.
//           {/* An LLM tool trained on thousands of coral related publications to help practitioers, new commers, researchers and policy makers */}
//         </p>

//         {!emailPromptVisible && (
//           <div className="search-section">
//             <Input
//               placeholder="Ask CoralAI a question..."
//               size="large"
//               style={{ display: 'flex', justifyContent: 'center'}}
//               className="search-input"
//               onKeyPress={(e) => e.key === 'Enter' && handleGoToEmailPrompt()} // Trigger email prompt
//               suffix={
//                 <Button
//                   type="primary"
//                   shape="circle"
//                   icon={<SendOutlined />}
//                   onClick={handleGoToEmailPrompt} // Trigger email prompt
//                 />
//               }
//             />
//           </div>
//         )}

//         {emailPromptVisible && (
//           <div className="email-prompt-section">
//             <p className="email-prompt-text">
//              {/* Our AI is under development, and under going internal testing.  Please provide your email, and we'll follow up with you. */}
//              Our AI is undergoing final safety checks and benchmarking. Share your email, and we’ll notify you when it’s available!
//              {/* Our AI is fully developed and currently undergoing final safety checks and benchmarking to ensure the best experience for you. Please provide your email, and we’ll follow up as soon as it’s ready for you to explore! */}
//             </p>
//             <Input
//               placeholder="Enter your email"
//               size="large"
//               className="email-input"
//               style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
//               value={email}
//               onChange={(e) => setEmail(e.target.value)} // Handle email input change
//               onKeyPress={(e) => e.key === 'Enter' && handleEmailSubmit()} // Submit on Enter key
//               suffix={
//                 <Button
//                   type="primary"
//                   onClick={handleEmailSubmit} // Submit email
//                 >
//                   Submit
//                 </Button>
//               }
//             />
//           </div>
//         )}

//         {feedbackMessage && (
//           <p className="feedback-message" style={{ marginTop: '20px', color: '#3f8600' }}>
//             {feedbackMessage}
//           </p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default LandingPage;


import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../lib/firebase"; // Import Firebase config

const LandingPage: React.FC = () => {
  const [emailPromptVisible, setEmailPromptVisible] = useState(false); // State to show/hide email input
  const [email, setEmail] = useState(''); // State to store email input
  const [feedbackMessage, setFeedbackMessage] = useState(''); // State for feedback after submission

  const handleGoToEmailPrompt = () => {
    setEmailPromptVisible(true); // Show email input
  };

  const handleEmailSubmit = async () => {
    if (email.trim()) {
      try {
        // Save email to Firestore
        const docRef = await addDoc(collection(db, "emails"), {
          email: email,
          timestamp: new Date(), // Optional: add a timestamp
        });
        console.log("Document written with ID: ", docRef.id);
        setFeedbackMessage(`Thank you! We'll follow up soon!`);
        setEmail(''); // Clear the input field
        setEmailPromptVisible(false); // Hide email input
      } catch (error) {
        console.error("Error adding document: ", error);
        setFeedbackMessage('Something went wrong. Please try again.');
      }
    } else {
      setFeedbackMessage('Please enter a valid email.');
    }
  };

  return (
    <div className="landing-page">
      {/* Overlay for fading effect */}
      <div className="fade-overlay"></div>

      {/* Main Content */}
      <div className="landing-page-content">
        <h1 className="landing-page-title">
          Welcome to <span className="coral-ai-highlight">Coral X AI</span> ✨
        </h1>
        <p className="landing-page-subtitle">
          An LLM tool trained on thousands of coral-related publications to assist practitioners, newcomers, researchers, and policymakers.
        </p>

        {!emailPromptVisible && (
          <div className="search-section">
            <Input
              placeholder="Ask CoralAI a question..."
              size="large"
              style={{ display: 'flex', justifyContent: 'center' }}
              className="search-input"
              onKeyPress={(e) => e.key === 'Enter' && handleGoToEmailPrompt()} // Trigger email prompt
              suffix={
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SendOutlined />}
                  onClick={handleGoToEmailPrompt} // Trigger email prompt
                />
              }
            />
          </div>
        )}

        {emailPromptVisible && (
          <div className="email-prompt-section">
            <p className="email-prompt-text">
              Our AI is undergoing final safety checks and benchmarking. Share your email, and we’ll notify you when it’s available to the public!
            </p>
            <Input
              placeholder="Enter your email"
              size="large"
              className="email-input"
              style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Handle email input change
              onKeyPress={(e) => e.key === 'Enter' && handleEmailSubmit()} // Submit on Enter key
              suffix={
                <Button
                  type="primary"
                  onClick={handleEmailSubmit} // Submit email
                >
                  Submit
                </Button>
              }
            />
          </div>
        )}

        {feedbackMessage && (
          <p className="feedback-message" style={{ marginTop: '20px', color: '#3f8600' }}>
            {feedbackMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
