export const INTENTS = {
  _edit_building: "_edit_building",
  _edit_unit: "_edit_unit",
  _edit_property: "_edit_property",
  _add_building: "_add_building",
  _add_property: "_add_property",
  _add_unit: "_add_unit",
  _signup: "_signup",
  _signin: "_signin",
  _invite_user: "_invite_user",
  _add_service_request: "_add_service_request",
  _edit_service_request: "_edit_service_request",
  _add_comment: "_add_comment",
  _add_image: "_add_image",
  _remove_property: "_remove_property",
  _remove_building: "_remove_building",
  _remove_unit: "_remove_unit",
  _remove_service_request: "_remove_service_request",
  _update_request_status: "_update_request_status",
};
