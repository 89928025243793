import React from 'react';

const HelpPage: React.FC = () => {
  return (
    <div className="help-page-container">
      <aside className="help-sidebar">
        <h2>Help Topics</h2>
        <ul>
          <li><a href="#overview">Overview</a></li>
          <li><a href="#how-it-works">How It Works</a></li>
          <li><a href="#getting-started">Getting Started</a></li>
          <li><a href="#privacy-security">Privacy and Security</a></li>
          <li><a href="#faqs">FAQs</a></li>
          <li><a href="#support">Support</a></li>
        </ul>
      </aside>

      <main className="help-content">
        <section id="overview">
          <h1>Help Page</h1>
          <h2>Overview</h2>
          <p>This project leverages user-contributed data to train a custom GPT model tailored to your personality and preferences. Through a secure and transparent process, we ensure that your data helps build a model that reflects your unique style while safeguarding your privacy.</p>
        </section>

        <section id="how-it-works">
          <h2>How It Works</h2>
          <ol>
            <li><strong>Data Collection:</strong> Your text data is periodically collected with your consent to ensure that the model accurately captures your tone and style.</li>
            <li><strong>Data Processing:</strong> The collected data undergoes preprocessing, including cleaning and filtering, to prepare it for effective model training.</li>
            <li><strong>Model Training:</strong> The preprocessed data is used to train a custom GPT model that aligns with your personality.</li>
            <li><strong>Model Deployment:</strong> Once trained, the personalized model is deployed for you to interact with seamlessly.</li>
          </ol>
        </section>

        <section id="getting-started">
          <h2>Getting Started</h2>
          <ol>
            <li><strong>Sign Up:</strong> Create an account with us to start the process and manage your settings.</li>
            <li><strong>Select Data Sources:</strong> Choose the sources from which you'd like to contribute data, such as emails, social media posts, etc.</li>
            <li><strong>Review and Consent:</strong> Review the data collection and privacy policy, ensuring full transparency and control.</li>
            <li><strong>Interact with Your AI:</strong> Once your personalized model is ready, you can begin interacting with it and observe how it adapts to your style.</li>
          </ol>
        </section>

        <section id="privacy-security">
          <h2>Privacy and Security</h2>
          <p>Your privacy and security are our top priorities. We use advanced encryption and data anonymization techniques to protect your data. You have full control over what data you share, and you can request deletion of your data at any time.</p>
        </section>

        <section id="faqs">
          <h2>FAQs</h2>
          <p><strong>Can I delete my data?</strong> Yes, you can request data deletion at any time through your account settings.</p>
          <p><strong>How long does the training process take?</strong> The training duration varies depending on the amount of data contributed, but it typically completes within a few hours to a day.</p>
          <p><strong>Is there a cost involved?</strong> The basic service is free, but premium features may incur a cost. Check our pricing page for details.</p>
        </section>

        <section id="support">
          <h2>Support</h2>
          <p>If you have questions, need assistance, or want to provide feedback, please reach out to our support team at <a href="mailto:support@example.com">support@example.com</a> or call us at 1-800-123-4567. We’re here to help you get the most out of your experience.</p>
        </section>
      </main>
    </div>
  );
};

export default HelpPage;
