import React, { useState, useEffect, useRef } from "react";
import { Layout, Button, Input, Avatar, Menu } from "antd";
import {
  LikeOutlined,
  DislikeOutlined,
  ReloadOutlined,
  SendOutlined,
  EditOutlined,
  PlusOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Sider, Content } = Layout;

type Message = {
  id: string;
  content: string;
  role: "user" | "assistant";
  timestamp: Date;
};

type Source = {
  citation: string;
  url: string;
};

const HomePage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [sources, setSources] = useState<Source[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  
  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []); 


  useEffect(() => {
    // Clear data on page load (refresh)
    const clearDataOnRefresh = () => {
      localStorage.clear();
      sessionStorage.clear(); // Optional
      console.log("Page refreshed: All data cleared.");
    };

    window.onload = clearDataOnRefresh;

    return () => {
      window.onload = null; // Cleanup on component unmount
    };
  }, []);

  
  useEffect(() => {
    // Fetch user messages from local storage
    const savedMessages = localStorage.getItem('chatMessages');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  useEffect(() => {
    // Store messages in local storage
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);


  const handleSend = async () => {
    if (!input.trim() || isLoading) return;

    const newMessage: Message = {
      id: Math.random().toString(),
      content: input,
      role: "user",
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, newMessage]);
    setInput("");
    setIsLoading(true);
    setError(null);

    try {
      const url = 'https://api.vectara.io/v2/corpora/RAG_app/query';
      const payload = {
        query: input,
        search: {
          custom_dimensions: {},
          lexical_interpolation: 0.025,
          semantics: 'default',
          offset: 0,
          limit: 10,
          context_configuration: {
            characters_before: 30,
            characters_after: 30,
            sentences_before: 3,
            sentences_after: 3,
            start_tag: '<em>',
            end_tag: '</em>',
          },
          reranker: {
            type: 'customer_reranker',
            reranker_name: 'Rerank_Multilingual_v1',
            limit: 5,
            cutoff: 0.8,
          },
        },
        generation: {
          generation_preset_name: 'vectara-summary-ext-v1.2.0',
          max_used_search_results: 10,
         prompt_template: `[
            {"role": "system", "content": "You are a helpful search assistant."},
            #foreach ($qResult in $vectaraQueryResults)
            {"role": "user", "content": "Using the $vectaraIdxWord[$foreach.index] search result."},
            {"role": "assistant", "content": "${"$"}{qResult.getText()}"},
            #end
            {"role": "user", "content": "Respond to the query '${"$"}{vectaraQuery}' strictly based on the provided context. 
            Do not use any external information. If the answer is not evident, respond with 'I do not know.' Avoid referencing 
            the context explicitly in your response (e.g., phrases like 'based on the provided context')."}
          ]`,
          // prompt_template: `[{"role": "system", "content": "You are a helpful search assistant."},{"role": "user", "content": "Respond based on provided context."}]`,
          max_response_characters: 300,
          response_language: 'auto',
          model_parameters: {
            max_tokens: 1000,
            temperature: 0,
            frequency_penalty: 0,
            presence_penalty: 0,
          },
          citations: {
            style: 'none',
            url_pattern: 'https://vectara.com/documents/{document_id}',
            text_pattern: '{document_id}',
          },
          enable_factual_consistency_score: false,
        },
        stream_response: false,
        save_history: false,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'x-api-key': 'zqt_gePi3DcVFC3LzgVs_E6Hq8xre1w9HERYlucl6g',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log('API Response:', data);

      if (response.ok && data.summary) {
        const aiResponse: Message = {
          id: Math.random().toString(),
          content: data.summary,
          role: 'assistant',
          timestamp: new Date(),
        };

        setMessages((prev) => [...prev, aiResponse]);

        const extractedSources = data.search_results.map((result: any) => ({
          citation: result.document_metadata?.citation || 'No citation available',
          url: result.document_metadata?.url || '#',
        }));

        const uniqueSources = extractedSources.filter(
          (source:any, index:number, self:any) =>
            index === self.findIndex((s:any) => s.citation === source.citation)
        );
        setSources(uniqueSources.slice(0, 4));
      } else {
        setError('No valid response received from the API.');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while fetching data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewChat = () => {
    setMessages([]);
    setSources([]);
    setError(null);
  };

  return (
    <Layout style={{ minHeight: "100vh", padding: "0px" }}>
      {/* Left Sidebar */}
      {!isMobile && (
        <Sider
          className="sidebar"
          width={250}
          style={{
            backgroundImage: `linear-gradient(
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0.4)
            ), url('/background.jpeg')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            backdropFilter: "blur(10px)",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div>
            <h2 style={{ color: "white", fontWeight: "bold", fontSize: "22px" }}>
              Coral AI
            </h2>
            <Button
              icon={<PlusOutlined />}
              type="default"
              block
              style={{
                borderColor: "white",
                color: "white",
                backgroundColor: "#374151",
                marginBottom: "20px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              }}
              onClick={handleNewChat}
            >
              New Chat
            </Button>
            <p
              style={{
                color: "#EFF6FF",
                fontSize: "14px",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Recent Chats
            </p>
            <Menu
              mode="inline"
              style={{
                backgroundColor: "transparent",
                color: "white",
                borderRight: "none",
              }}
            >
              <Menu.Item
                key="1"
                icon={<MessageOutlined style={{ color: "#6b7280" }} />}
                style={{
                  backgroundColor: "#374151",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px 15px",
                  color: "white",
                }}
              >
                What are corals
              </Menu.Item>
            </Menu>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              height: "74%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Avatar
              size={64}
              icon={<UserOutlined />}
              style={{ marginBottom: "10px", marginLeft: "70px" }}
            />
            <p style={{ color: "white", fontSize: "16px", margin: 0 }}>
              User Admin
            </p>
            <p style={{ color: "#9ca3af", fontSize: "12px" }}>AI Researcher</p>
          </div>
        </Sider>
      )}

      {/* Main Content */}
      <Layout>
        <Content style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
          <div className="chat-messages" ref={parentRef}>
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.role}`}>
                <div className="message-avatar">
                  {message.role === "user" ? "U" : "A"}
                </div>
                <div className="message-content">
                  <p>{message.content}</p>
                  {message.role === "assistant" && (
                    <div className="message-actions">
                      <Button type="text" icon={<LikeOutlined />} />
                      <Button type="text" icon={<DislikeOutlined />} />
                      <Button type="text" icon={<EditOutlined />} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {error && (
            <div className="chat-error">
              <ReloadOutlined /> {error}
            </div>
          )}

          <footer className="chat-footer" style={{ marginTop: "20px" }}>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSend()}
              placeholder="Type your message..."
              className="chat-input"
            />
            <Button
              onClick={handleSend}
              type="primary"
              icon={<SendOutlined />}
              loading={isLoading}
              className="send-button"
            />
          </footer>
        </Content>
      </Layout>

   {/* Sources Sidebar */}
      {!isMobile && (
<Sider
  width={250}
  style={{
    backgroundColor: "#EFF6FF",
    padding: "20px",
    overflowY: "auto", // Enable scrolling if content exceeds height
  }}
>
  <h2
    className="link-title"
    style={{
      marginBottom: "15px",
      fontSize: "18px",
      fontWeight: "bold",
      textAlign: "center", // Align the title
    }}
  >
    Sources to Response
  </h2>
  {sources.length > 0 ? (
    <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
      {sources.map((source, index) => (
        <li
          key={index}
          style={{
            marginBottom: "10px",
            fontSize: "14px",
            color: "#2563EB",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis", // Truncate text with ellipsis
          }}
          title={source.citation} // Show full citation on hover
        >
          <a
            href={source.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#2563eb",
              textDecoration: "none",
            }}
          >
            {source.citation}
          </a>
        </li>
      ))}
    </ul>
  ) : (
    <p style={{ fontSize: "14px", color: "#6b7280", textAlign: "center" }}>
      No sources available.
    </p>
  )}
</Sider>
      )}
      </Layout>
  );
};

export default HomePage;
