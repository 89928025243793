import React from "react";
import { Layout, Menu, Button, Avatar } from "antd";
import { MessageOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";

const SideNavigation = () => {
  const { Sider } = Layout;

  return (
    <Sider
      width={250}
      style={{
        backgroundColor: "#1f2937", // Match your design color
        color: "white",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Top Section */}
      <div>
        {/* Title Section */}
        <div className="sidebar-title" style={{ marginBottom: "30px" }}>
          <h2 style={{ color: "white", fontWeight: "bold", fontSize: "22px" }}>
            Coral AI
          </h2>
        </div>

        {/* New Chat Button */}
        <Button
          icon={<PlusOutlined />}
          type="default"
          block
          style={{
            borderColor: "white",
            color: "white",
            backgroundColor: "#374151", // Slightly lighter color for the button
            marginBottom: "20px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
          }}
        >
          New Chat
        </Button>

        {/* Previous Chats Section */}
        <div className="previous-chats">
          <p style={{ color: "#9ca3af", fontSize: "14px", marginBottom: "10px" }}>
            Recent Chats
          </p>
          <Menu
            mode="inline"
            style={{
              backgroundColor: "#1f2937",
              color: "white",
              borderRight: "none",
            }}
          >
            <Menu.Item key="1" icon={<MessageOutlined style={{ color: "#6b7280" }} />}>
              Platform Marketplace 101
            </Menu.Item>
            <Menu.Item key="2" icon={<MessageOutlined style={{ color: "#6b7280" }} />}>
              Proposal for Company
            </Menu.Item>
            <Menu.Item key="3" icon={<MessageOutlined style={{ color: "#6b7280" }} />}>
              Research about UI/UX
            </Menu.Item>
          </Menu>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="user-profile" style={{ textAlign: "center", marginTop: "20px" }}>
        <Avatar size={64} icon={<UserOutlined />} style={{ marginBottom: "10px" }} />
        <p style={{ color: "white", fontSize: "16px", margin: 0 }}>Joanne Nguyen</p>
        <p style={{ color: "#9ca3af", fontSize: "12px" }}>AI Researcher</p>
      </div>
    </Sider>
  );
};

export default SideNavigation;
