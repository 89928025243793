import React, { useEffect, useState } from "react";
import "react-photo-view/dist/react-photo-view.css";
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { Spin } from "antd";
import {
  SideNavigation,
  TopNavigation,
  SideActions,
  AppLayout,
} from "./components";
import { LandingPage, SignUpPage, HomePage, HelpPage } from "./pages";
import { auth } from "./lib/firebase";

import {
  SignUpPageAction,
  SignUpPageErrorBoundary,
  SignUpPageLoader,
} from "./pages/SignUpPage/loaders";

const App: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Spin />
      </div>
    );
  }

  const pageStyling = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  };

  const routesJSX = (
    <Route>
      <Route
        path="/"
        element={
          <AppLayout
            TopNavigation={null}
            SideNavigation={null}
            SideAction={null}
            PageContent={
              <div style={pageStyling}>
                <LandingPage />
              </div>
            }
          />
        }
      />
      <Route
        path="/signup"
        loader={SignUpPageLoader}
        action={SignUpPageAction}
        ErrorBoundary={SignUpPageErrorBoundary}
        element={
          <AppLayout
            TopNavigation={<></>}
            SideNavigation={null}
            SideAction={null}
            PageContent={
              <div style={pageStyling}>
                {!user ? <SignUpPage /> : <Navigate to="/home" replace={true} />}
              </div>
            }
          />
        }
      />
      <Route
        path="/home"
        element={
          <AppLayout
            TopNavigation={<TopNavigation />}
            SideNavigation={<SideNavigation />}
            SideAction={<SideActions />}
            PageContent={
              <div style={{ height: "100%", width: "100%" }}>
                <HomePage />
              </div>
            }
          />
        }
      />
      <Route
        path="/help"
        element={
          <AppLayout
            TopNavigation={<TopNavigation />}
            SideNavigation={<SideNavigation />}
            SideAction={<SideActions />}
            PageContent={
              <div style={pageStyling}>
                {user ? (
                  <HelpPage />
                ) : (
                  <Navigate to="/" replace={true} />
                )}
              </div>
            }
          />
        }
      />
    </Route>
  );

  const routes = createRoutesFromElements(routesJSX);

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default App;
